<template>
  <layout-login>
    <template v-slot:title>
      {{ __('interface.forgot-password') }}
    </template>
    <div class="ion-padding">{{ __('interface.forgot-password-enter-email') }}</div>
    <ion-item>
      <ion-label position="floating">{{ __('interface.email') }}</ion-label>
      <ion-input type="email" v-model="form.email" required/>
    </ion-item>
    <ion-button expand="full" class="ion-margin-vertical" @click="forgotPassword">
      {{ __('interface.forgot-password-send-email') }}
    </ion-button>
    <div class="ion-padding ion-text-center">
      <router-link to='/login'>{{ __('interface.go-login') }}</router-link>
    </div>
    <div class="ion-text-center ion-padding">
      <router-link to="/forgot-password-code">{{ __('interface.forgot-password-go-code') }}</router-link>
    </div>
  </layout-login>
</template>

<script>
import {Auth} from '@aws-amplify/auth'
import LayoutLogin from '../../layouts/LayoutLogin'
import {alertController, loadingController} from "@ionic/vue";
import AuthService from "@/views/AuthService";

export default {
  name: 'Login',
  mixins: [AuthService],
  components: {
    LayoutLogin,
  },
  data() {
    return {
      form: {
        email: ''
      },
      loadingState: false
    }
  },
  async ionViewWillEnter() {
    await this.checkNoAuth();
  },
  methods: {
    async forgotPassword() {
      const loading = await loadingController.create({
        message: this.__('interface.loading'),
      });
      await loading.present();

      Auth.forgotPassword(this.form.email).then(() => {
        this.$router.push('/forgot-password-code')
        this.$tracking.event({
            eventName: 'forgotten-password',
            category: 'auth ',
            event: {
              username: this.form.email
            }
          });
      }).catch(error => {
        this.showAlert('Error', error.message)
      }).finally(() => {
        loading.dismiss();
      })
    },
    async showAlert(title, error) {
      return (await alertController.create({
        header: title,
        message: error,
        buttons: ['OK'],
      })).present();
    }
  }
}
</script>
